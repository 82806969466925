import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TheatreMap from "../components/TheatreMap"

const sourceStyle = {
  marginBottom: 20,
}

const AboutPage = () => (
  <Layout>
    <SEO title="About this site" />
    <h2>About this site</h2>
    <p>
      Our site was borne from a frustration with the movie listing websites for
      Vancouver, British Columbia. We want, selfishly, a place where we can see
      all of the important upcoming films in the city aggregated in one
      convenient interface.
    </p>
    <p>
      We support local cinemas and independent and ambitious filmmakers and
      programmers.
    </p>
    <p>
      Feedback?{" "}
      <a href="mailto:feedback@filmsheet.ca">We'd love to hear from you!</a>
    </p>
    <h3>Our sources</h3>
    <div style={sourceStyle}>
      <h4>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="http://www.thecinematheque.ca/"
        >
          The Cinematheque
        </a>
      </h4>{" "}
      This arthouse institution has shown carefully programmed fare for over
      forty years.
      <p style={{ margin: `10px 0`, fontSize: `0.9rem` }}>
        <strong>1131 Howe St, Vancouver, BC</strong>
      </p>
      <TheatreMap
        text="The Cinematheque"
        coordinates={[49.2786691, -123.1264603]}
      />
    </div>
    <div style={sourceStyle}>
      <h4>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://viff.org/Online/default.asp"
        >
          Vancity Theatre
        </a>
      </h4>{" "}
      The home of the Vancouver Film Festival, this luxurious cinema shows
      unique films all year long.
      <p style={{ margin: `10px 0`, fontSize: `0.9rem` }}>
        <strong>1181 Seymour St, Vancouver, BC</strong>
      </p>
      <TheatreMap
        text="Vancity Theatre"
        coordinates={[49.2769972, -123.124965]}
      />
    </div>
    <div style={sourceStyle}>
      <h4>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="http://riotheatre.ca/"
        >
          Rio Theatre
        </a>
      </h4>{" "}
      A neighbourhood institution known for live music and events as well as
      interestingly curated films. Licensed!
      <p style={{ margin: `10px 0`, fontSize: `0.9rem` }}>
        <strong>1660 E Broadway, Vancouver, BC</strong>
      </p>
      <TheatreMap
        text="Rio Theatre"
        coordinates={[49.26209295, -123.070449144575]}
      />
    </div>
    <div style={sourceStyle}>
      <h4>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.cineplex.com/Showtimes/any-movie/fifth-avenue-cinemas-age-restricted-19"
        >
          Fifth Avenue Cinema
        </a>
      </h4>{" "}
      Megaplex owned but sometimes reminisces about being an indie theatre.
      <p style={{ margin: `10px 0`, fontSize: `0.9rem` }}>
        <strong>2110 Burrard St, Vancouver, BC</strong>
      </p>
      <TheatreMap text="Rio Theatre" coordinates={[49.266804, -123.14522]} />
    </div>
    <div style={sourceStyle}>
      <h4>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.cineplex.com/Showtimes/any-movie/cineplex-odeon-international-village-cinemas"
        >
          International Village
        </a>
      </h4>{" "}
      Housed in the world's strangest mall, sometimes programs astonishing
      exceptions to its normally megaplex-y fare.
      <p style={{ margin: `10px 0`, fontSize: `0.9rem` }}>
        <strong> 88 W Pender St, Vancouver, BC</strong>
      </p>
      <TheatreMap text="International Village" coordinates={[49.2802068, -123.1068483]} />
    </div>
  </Layout>
)

export default AboutPage
