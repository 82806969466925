import React, { Component } from "react"
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet"
import "../styles/TheatreMap.css"

const zoomLevel = 15

export default class TheatreMap extends Component {
  render() {
    const mapCenter = this.props.coordinates

    if (typeof window !== "undefined") {
      return (
        <div>
          <MapContainer center={mapCenter} zoom={zoomLevel}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={mapCenter}>
              <Popup>{this.props.text}</Popup>
            </Marker>
          </MapContainer>
        </div>
      )
    }
    return null
  }
}
